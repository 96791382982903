@import "Style/normalize.css";

:root {
  --global-background-color: #fada73;
  --global-background-color-dark: #ffb63b;
  --global-text-color: #3b3735;

  --global-dark: #3b3735;
  --global-cta: #e76f51;
  --global-inactive: #fada9e;
  --global-pink: #ea4c6d;

  --local-header-height: 96px;
  --local-footer-height: 172px;
  --global-divider-height: 144px;
  --global-app-height: calc(
    100vh - (var(--local-header-height) + var(--local-footer-height))
  );
}

@font-face {
  font-family: atten-regular;
  src: url("../Fonts/AttenRoundNewRegular.otf");
}
@font-face {
  font-family: atten-bold;
  src: url("../Fonts/AttenRoundNewBold.otf");
}
@font-face {
  font-family: atten-medium;
  src: url("../Fonts/AttenRoundNewMedium.otf");
}
@font-face {
  font-family: atten-extra-bold;
  src: url("../Fonts/AttenRoundNewExtraBold.otf");
}

button,
input,
optgroup,
select,
textarea,
html input[type="button"],
input[type="reset"],
input[type="submit"],
button[disabled],
html input[disabled],
button::-moz-focus-inner,
input::-moz-focus-inner,
input[type="checkbox"],
input[type="radio"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* custom scrollbar */

::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius: 10px;
  background-color: var(--global-background-color);
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

button:hover,
button:active {
  opacity: 0.5;
  cursor: pointer;
}

html {
  font-size: 16px;
  font-family: atten-regular;
}

h1 {
  font-size: 3rem;
  color: white;
  font-family: atten-extra-bold;
}

h3 {
  color: var(--global-text-color);
  font-size: 2rem;
  font-family: atten-medium;
}
h4 {
  color: var(--global-text-color);
  font-size: 1.75rem;
  font-family: atten-medium;
}
h5 {
  color: var(--global-cta);
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: atten-extra-bold;
}

p {
  color: var(--global-text-color);
  font-family: atten-regular;
  font-size: 1.2rem;
}

body {
  font-family: atten-regular, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--global-background-color);
  color: var(--global-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.c-address > address {
  display: block;
}

.c-attribution {
  font-size: 0.75rem;
}

.c-attribution-link {
  color: var(--global-text-color);
  text-decoration: none;
}

.c-footer-row__item a {
  text-decoration: none;
  color: var(--global-text-color);
}

.c-attribution-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
}

.MuiButtonBase-root,
.MuiPaginationItem-root,
.MuiPaginationItem-page {
  font-family: atten-regular !important;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.c-app {
  min-height: var(--global-app-height);
  padding: 0 1rem 2rem;
}

.c-divider {
  border: 0;
  margin: 0 -0.5rem 1rem;
  border-top: 1px solid #b8b7b8;
  background: #b8b7b8;
}

.c-header__homepage {
  margin: 0 -1rem -2rem;
  width: 100%;
  width: calc(100% + 2rem);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  border-radius: 0px 0px 100px 100px;
  color: #3b3735;
  /* background-image: linear-gradient(
      rgba(59, 55, 53, 0.5),
      rgba(59, 55, 53, 0.5)
    ),
    url("https://wordpress.kaj.be/wp-content/uploads/2021/05/header_desk.png"); */
}

.c-nav__list {
  display: none;
  margin: 0;
  padding: 0;
}

.c-nav__logo--mobile {
  height: 3rem;
}
.c-nav__logo {
  height: 64px;
  margin-left: 64px;
  margin-right: 32px;
}

.c-header__logo {
  width: 2rem;
  height: 50px;
  position: absolute;
  top: 40px;
  left: 29px;
}

.c-header__moto > h1 {
  margin: 0.5rem;
  font-size: 2rem;
}

.c-header__moto {
  top: 0;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-menu-card {
  margin: auto;
  padding: 1rem 1rem;
  background-color: white;
  height: 128px;
  width: 124px;
  border-radius: 20px;
  text-decoration: none;
}

.c-menu-card__row {
  /* margin: -3rem -3rem -2rem; */
  /* width: calc(100% + 6rem); */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(9.75rem, 1fr));
  row-gap: 1rem;
  justify-items: center;
  align-items: center;
  margin-bottom: 1rem;
}

.c-menu-card__item {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.c-menu-card__image {
  /* height: 100%; */
  height: 48px;
}
.c-menu-card__title {
  text-align: center;
  margin: 0;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  max-width: 182px;
}

.c-homepage-divider {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.c-homepage-divider > h4 {
  margin: 0.5rem;
}

.c-kijker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 96px;
  border-radius: 3rem;
  background-color: var(--global-cta);
}

.c-kijker__icon {
  max-height: 3rem;
  max-width: 3rem;
}

.c-kijker-posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;
  /* width: 100%; */
}

.o-row__footer {
  background-color: var(--global-background-color-dark);
  padding: 0 24px 0;
  display: flex;
  flex-direction: column;
  border-radius: 100px 100px 0px 0px;
}
.c-footer-title {
  margin: 1rem 0;
  text-align: center;
}
.c-footer-row {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.c-footer-row__column {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
}

.c-footer-row__column-row {
  flex-direction: row;
  justify-content: center;
}

.c-footer-row__item {
}

.c-footer-row__item > p {
  margin: 0;
  margin-bottom: 0.75rem;
}

.c-footer-social {
  height: 48px;
  width: 48px;
  margin: 0 8px;
}

.c-footer-social-row {
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-around;
}

.c-footer-row__sponsor {
  /* max-height: 80px; */
  height: auto;
  width: 50%;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.c-footer-row--sponsors {
  max-height: 25%;
}

/*
    Object: Button reset
    ---
    Small button reset object
*/

.o-button-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  outline: none;
}

.c-button {
  color: white;
  font-family: atten-extra-bold;
  font-size: 20px;
  background-color: var(--global-cta);
  border-radius: 64px;
  padding: 24px;
  margin-bottom: 16px;
  cursor: pointer;
}

.c-button:active,
.c-button:hover {
  opacity: 0.8;
}

.c-blogpost {
  /* padding: 1rem 1.25rem;
  margin-bottom: 2rem;
  width: 100%; */
}

.c-blogpost__image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.c-blogpost__head {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  max-height: 258px;
  object-fit: contain;
}

.c-blogpost__head-new {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  max-height: 258px;
  object-fit: cover;
}

.c-blogpost__title {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.75rem;
}

.c-blogpost > h2 {
  font-size: 1.5rem;
}

.c-blogpost > ul > li {
  font-size: 1rem;
}

.c-blogpost__info {
  font-family: atten-regular;
}
p.c-blogpost__author,
p.c-blogpost__publishdate {
  color: #707070;
  font-size: 1rem;
}
p.c-blogpost__author {
  margin: 0;
}

.c-blogpost__publishdate {
  margin: 0;
}

.c-blogpost__body {
  margin-top: 2rem;
}

.c-blogpost__body > h2 {
  font-family: atten-extra-bold;
  color: var(--global-text-color);
  margin: 0.5rem 0 1rem;
  font-size: 1.1rem;
}

.c-blogpost__body > ul {
  padding-inline-start: 1rem;
}

.c-blogpost__body > p {
  font-size: 1rem;
  font-family: atten-medium;
  margin-bottom: 1.5rem;
  max-width: 100%;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.c-blogpost__body > blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
.c-blogpost__body > blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.c-blogpost__body > blockquote p {
  display: inline;
}

/* .c-blogpost-container {
  padding: 1rem 1.25rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 1rem;
} */

.c-blogpostcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 272px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.c-blogpostcard > h5 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 1.5rem;
  text-align: center;
}

p.c-blogpostcard__author,
p.c-blogpostcard__publishdate,
div.c-blogpostcard__excerpt {
  display: none;
  font-family: atten-regular;
}

div.c-blogpostcard__excerpt {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

p.c-blogpostcard__author,
p.c-blogpostcard__publishdate {
  color: #707070;
}

.c-blogpost li {
  font-size: 1.5rem;
  margin-left: 1rem;
}

.c-blogpost h2 {
  font-size: 2rem;
}

.c-blogpostcard__excerpt {
  font-family: atten-medium;
}

.c-blogpostcard__img {
  width: 238px;
  height: auto;
  border-radius: 10px;
}

.c-readmore {
  margin-top: 8px;
  color: var(--global-cta);
}

.c-nieuws-container {
  margin-bottom: 2rem;
}

.c-mobile-nav {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
  background-color: var(--global-background-color-dark);
  border-radius: 0 0 35px 35px;
}

.c-mobile-nav--absolute {
  position: absolute;
  top: 16px;
  right: 16px;
}

.c-mobile-nav__icon {
  border-radius: 12px;
}

.c-mobile-nav__body {
  z-index: 1;
  background-color: var(--global-cta);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 32px;
}

.c-mobile-nav__icon-container--open {
  position: absolute;
  top: 16px;
  right: 80px;
  background-color: var(--global-background-color);
  border-radius: 12px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-mobile-nav__icon-container {
  border-radius: 12px;
  height: 50px;
  width: 50px;
  background-color: var(--global-cta);
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-nav__item {
  list-style: none;
  margin-bottom: 32px;
}

.c-nav__link {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  font-family: atten-extra-bold;
  text-transform: uppercase;
}

.c-usercard__grid {
  margin: 0 -3rem -2rem;
  width: calc(100% + (3rem * 2));
  /* margin: 2rem 1rem; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  margin-bottom: 3rem;
}

.c-usercard {
  background-color: #fff;

  border-radius: 20px;
  padding: 1rem;
  margin: 0.5rem 3rem 0;
  /* max-width: 70%; */
}

.c-usercard__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.c-usercard__img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.c-usercard__title {
  margin: 0;
  color: var(--global-dark);
  text-transform: none;
  font-family: atten-extra-bold;
  margin-top: 16px;
  /* margin-bottom: 8px; */
  font-size: 1rem;
}
.c-usercard__description {
  font-family: atten-medium;
  margin: 0;
  margin-bottom: 8px;
  font-size: 1rem;
  text-align: center;
}

.c-usercard__subtext {
  font-family: atten-regular;
  font-size: 1rem;
}

p.c-usercard__subtext {
  margin: 0;
}
a.c-usercard__subtext {
  color: var(--global-dark);
  text-decoration: none;
}

.active {
  color: white !important;
}
.backgroundcolor {
  background-color: var(--global-background-color-dark) !important;
}

.c-page,
.c-blogpost-container {
  padding: 1rem 1.25rem;
  width: 100%;
  box-sizing: border-box;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 1rem;
}

.c-page > div {
}

.c-page > div:nth-child(even) {
}
.c-page > div:nth-child(odd) {
}

.c-page > img {
  margin: -1rem -1.25rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
  background-size: contain;
  width: calc(100% + (1.25rem * 2));
  height: 10rem;
}

.c-page > img + p {
  margin-top: 3rem;
}

.c-page > p {
  font-size: 1rem;
  font-family: atten-medium;
  margin: 0 0 0.5rem 0;
  margin-bottom: 1.5rem;
  line-height: 1.25rem;
}

.c-page > h2 {
  color: white;
  background-color: var(--global-pink);
  width: fit-content;
  /* max-width: calc(100% - 40px); */
  margin: 0;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  line-height: 3rem;
  font-size: 6vw;
  font-family: atten-extra-bold;
  text-transform: uppercase;
  border-radius: 2px;
}

.c-page > img + h2 {
  position: relative;
}

.c-page > h3 {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

.c-page > h4 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.c-page > ul {
  /* margin: 0; */
  /* margin-bottom: 5rem; */
}

.c-page > p > ul {
  margin-top: -2rem !important;
}

.c-page > ul > li {
  margin: 0.5rem 0 0.5rem;
}

.c-page > p > img {
  border-radius: 40px;
}

.c-productgrid {
  /* margin: 0 -3rem -2rem; */
  /* width: calc(100%); */
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  background-color: #fff;
  border-radius: 20px;
}

.c-shoprow {
  display: flex;
  /* width: calc(100% + 6rem); */
  flex-direction: column;
  margin-bottom: 3rem;
}

.c-productcard {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin: auto; */
  border-radius: 1rem;
  min-width: 10%;
  border-radius: 20px;
  padding: 1rem;
  text-decoration: none;
  justify-content: center;
}

.c-productcard > img {
  /* margin: auto; */
}

bdi {
  color: var(--global-text-color);
}

.c-productcard > img {
  width: 7rem;
  max-height: 140px;
  object-fit: contain;
  margin: auto;
}

.c-productcard > h3 {
  margin: 0;
  font-size: 1rem;
  max-width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0.5rem 0 0.5rem;
}

.c-categorycard {
  padding: 1rem;
  border-radius: 20px;
  /* margin-right: -9px; */
  background-color: var(--global-background-color-dark);
  margin-bottom: 1rem;
}

.c-category__open {
}

.c-categorycard > ul {
  list-style-type: none;
}
.c-categorycard > ul > li {
  margin-bottom: 1rem;
}

.c-categorycard__title {
  margin: 0;
  /* margin: 1rem; */
}
/* .c-app > .c-map {
  height: 100vh;
  width: 100vw;
} */

.c-categorycard__control-row {
  display: flex;
  min-width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

ul > li > .c-nav__link {
  list-style-type: none;
}

.c-map-container {
  z-index: 0;
  border-radius: 20px;
  width: 100%;
  height: 75vh;
  height: calc(100vh - (176px + 96px + 16px));
  margin-bottom: 2rem;
}

.c-productdetail-container {
  border-radius: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem 2rem;
  margin-top: 2rem;
  width: calc(100% - (1rem * 2));
}

.c-productdetail-container > h2 {
  margin-bottom: 8px;
}

.c-productdetail-container > p {
  margin-top: 8px;
}

.c-product__button {
  background-color: var(--global-text-color);
  color: white;
  font-size: 1rem;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 0.5rem;
}

.c-product__details {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

.c-product__img {
  /* max-width: 50%; */
  /* margin: auto; */
  align-content: center;
  margin: auto;
}

.c-product__primary-img-row {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.c-product__img-secondary {
  margin: 0 8px;
}
.c-product__secundary-img-row {
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
}
/* .c-product__img-grid {
  display: grid;
  grid-template-rows: 2fr 1fr;
  /* grid-row: 2; 
} */

.c-product__desc {
  /* max-width: 50%; */
}

.c-product__button:active {
  opacity: 0.7;
}
.c-product__button:hover {
  opacity: 0.9;
}

.c-product__img {
  width: auto;
  height: 140px;
  object-fit: contain;
}

.c-productcontrols {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.c-basket-button {
  margin-top: 16px;
  padding-top: 8px;
  background-color: var(--global-cta);
  border-radius: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  width: 160px;
  cursor: pointer;
}

.c-basket-button > h5 {
  color: white;
  margin: 0;
  margin-bottom: 8px;
}

.c-basket-button:hover {
  opacity: 0.8;
}

.c-basket-button__image {
  height: 24px;
}

.c-productcontrol-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-breadcrumbs > p {
  margin: 0;
  margin-top: 2rem;
}

.c-breadcrumbs__link {
  text-decoration: none;
  color: var(--global-dark);
}

.c-page-container {
  background-color: white;
  width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 16px;
  border-radius: 20px;
  object-fit: contain;
}

.c-eventcard {
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 1rem 1rem;
  text-decoration: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.c-eventcard__datecontainer {
  text-align: center;
}

.c-input {
  border: 1px solid var(--global-dark);
  height: 2rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  outline: none;
  padding: 0 0.5rem;
  background-color: #fff;
}

.c-eventcard__day,
.c-eventcard__month,
.c-eventcard__year {
  padding: 0;
  margin: 0;
}

.c-eventcard__day,
.c-eventcard__month,
.c-eventcard__year,
.c-eventcard__title {
  font-family: atten-extra-bold;
}

.c-eventcard__day,
.c-eventcard__year {
  color: var(--global-text-color);
}

.c-eventcard__day {
  font-size: 2rem;
}

.c-eventcard__month {
  color: var(--global-cta);
}

.c-eventcard__titlecontainer {
  margin-left: 1.25rem;
}
.c-eventcard__title {
  color: var(--global-cta);
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.c-post__row img {
  border-radius: 20px;
}

.c-winkel-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.align-end {
  justify-content: flex-end;
}

.c-winkel-items > p {
  margin-top: 8px;
}

.c-winkel-items {
  margin-left: 1rem;
}

.c-button-to-checkout {
  border-radius: 12px;
  background-color: var(--global-cta);
  color: white;
  font-family: atten-bold;
  padding: 0.5rem 1rem;
}
.c-button-continue-shopping {
  background-color: var(--global-background-color);
  border-radius: 12px;
  color: white;
  font-family: atten-bold;
  padding: 0.5rem 1rem;
}

.c-basket {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.1fr;
  object-position: center;
  margin: 1rem 0;
}

.c-basket-img {
  height: 4rem;
}

.c-basket-cancel {
  margin: auto;
  cursor: pointer;
  color: var(--global-text-color);
}

.c-basket-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-basket-title {
  margin: 0;
  text-align: left;
}

.c-basket-desc,
.c-basket-input,
.c-basket-price {
  margin: auto;
}
.c-productdetail-inforow {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.c-form {
  display: flex;
  flex-direction: column;
}

.c-productdetail__desc > p {
  margin: 0;
}

input[type="text"],
input[type="number"],
input[type="email"] {
  border: 1px solid var(--global-dark);
  height: 2rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
  outline: none;
  padding: 0 0.5rem;
}

textarea {
  padding: 0.5rem 0.5rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  outline: none;
  resize: none;
}

.c-form__input--margin {
  margin-right: 1rem;
}

.c-form-button {
  background-color: var(--global-cta);
  border-radius: 12px;
  color: white;
  font-family: atten-bold;
  padding: 0.5rem 1rem;
  align-self: flex-start;
  width: 10rem;
  height: 3rem;
  cursor: pointer;
  /* max-width: 25%; */
}

.c-afdeling-section {
  background-color: white;
  border-radius: 20px;
  padding: 1rem 1.25rem;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
  max-height: calc(100vh - (176px + 96px + 16px));
  overflow: auto;
}

.c-afdeling-section h1 {
  line-height: 3rem;
  padding: 0 0.5rem;
  width: max-content;
  font-size: 1.75rem;
  text-transform: uppercase;
  background-color: var(--global-pink);
}

section.c-afdeling-section code,
address {
  display: none;
}

section.c-afdeling-section h2,
p {
  margin: 0;
}

section.c-afdeling-section h2 {
  margin-bottom: 0.25rem;
}

section.c-afdeling-section p {
  margin-bottom: 1rem;
}

.c-shoppage {
}

.category {
  cursor: pointer;
}

@media (min-width: 768px) {
  .c-footer-row {
    flex-direction: row;
  }
  .c-productgrid {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
  .c-form {
    padding: auto;
    width: 75%;
  }
  .c-form__input {
  }

  .c-form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .c-productdetail-inforow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .c-calendar-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
  }
  .c-blogpost__head {
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 240px;
    object-fit: cover; */
  }
  .c-app {
    padding: 0 3rem 2rem;
  }
  .c-header__homepage {
    margin: 0 -3rem -2rem;
    width: calc(100% + (3rem * 2));
    /* background-image: linear-gradient(
        rgba(59, 55, 53, 0.5),
        rgba(59, 55, 53, 0.5)
      ),
      url("https://wordpress.kaj.be/wp-content/uploads/2021/05/header_desk.png"); */
  }

  .c-post__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .c-post__row-right,
  .c-post__row-left {
    min-width: 50%;
  }

  .c-post__row-right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-shop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 100%);
    padding: 1rem;
  }
  .c-blogpost {
    /* align-self: center; */
    max-width: 75%;
  }
  .c-menu-card__row {
    width: 100%;
    /* grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr)); */
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
  .c-header__homepage {
    /* background-image: linear-gradient(
        rgba(59, 55, 53, 0.5),
        rgba(59, 55, 53, 0.5)
      ),
      url("https://wordpress.kaj.be/wp-content/uploads/2021/05/header_desk.png"); */
  }

  .c-page,
  .c-blogpost-container {
    padding: 1.5rem 4rem;
    max-width: 75%;
    margin: auto;
    margin-top: 2rem;
  }

  .c-page > img {
    /* align-self: center; */
    height: 14rem;
    margin: -1.5rem -4rem;
    width: calc(100% + (4rem * 2));
  }

  .c-page > ul > li {
    font-size: 1.5rem;
  }

  .c-blogpost__body > p,
  li {
    font-size: 1.5rem;
  }

  .c-page > p {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .c-page > h2 {
    font-size: 2.5rem;
  }

  .c-page > h3 {
    font-size: 1.75rem;
  }

  .c-page > h4 {
    font-size: 1.25rem;
  }

  .c-post__row {
    margin-bottom: 2rem;
    width: 100%;
  }

  .c-page > .c-post__row:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
  }

  .c-page > div:nth-child(even) {
    align-self: flex-end;
  }

  .c-product__details {
    max-width: 50%;
  }
}

button.c-nav__link:hover {
  opacity: 1;
}

.c-nav__dropdownlist {
  /* margin-top: 8px; */
  /* position: absolute; */
  padding-top: 8px;
  opacity: 1;
  text-align: left;
  list-style: none;
  padding-inline-end: 40px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: 0;
}

.c-nav__dropdown-item {
  padding-bottom: 10px;
}

.c-nav__dropdownlist > .c-nav__dropdown-item > .c-nav__link {
  font-size: 1rem;
}

input[type="radio"]:checked + label {
  border-bottom: 1px solid var(--global-text-color);
  cursor: default;
}

li > svg.c-cancel {
  display: none !important;
}

li > input[type="radio"]:checked + label + svg.c-cancel {
  margin-left: 0.5rem;
  display: inline-block !important;
  cursor: pointer;
}

.c-praktisch-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  row-gap: 1rem;
}

.c-praktisch-card-holder {
  text-decoration: none;
}
.c-praktisch-card {
  border-radius: 32px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.c-praktisch-card__title {
  color: white;
  text-align: center;
}

@media (min-width: 992px) {
  .c-blogpost__head {
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 320px;
    object-fit: cover; */
  }
  .c-nav__dropdownlist {
    top: 96px;
    position: absolute;
    opacity: 1.5;
    text-align: left;
    list-style: none;
    padding-inline-end: 8px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .c-nav__dropdown-item {
    margin-bottom: 8px;
  }

  .c-post__row-left > h2,
  .c-post__row-right > h2 {
    font-size: 3rem;
  }
  .c-post__row-left > p,
  .c-post__row-right > p {
    font-size: 1.5rem;
  }

  .c-app {
    min-height: var(--global-app-height);
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .c-app > h2 {
    font-size: 3rem;
  }

  .c-app > p {
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }

  .c-app > ul {
    font-size: 1.5rem;
  }

  .c-blogpostcard__c2 {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-content: center;
  }
  .c-blogpostcard__c1,
  .c-blogpostcard__c2 {
    width: 50%;
  }

  h5.c-blogpostcard__title {
    margin-top: 16px;
    align-self: flex-start;
  }
  .c-blogpostcard__row {
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    align-items: center;
  }
  .c-blogpostcard {
    width: calc(75% - 144px);
  }

  div.c-blogpostcard__excerpt {
    font-size: 1.5rem;
  }

  div.c-blogpostcard__excerpt,
  p.c-blogpostcard__author,
  p.c-blogpostcard__publishdate {
    display: block;
    margin: 0;
  }

  p.c-blogpostcard__author,
  p.c-blogpostcard__publishdate {
    font-size: 1rem;
  }
  .c-blogpostcard__info {
    margin-bottom: 16px;
    align-self: flex-start;
  }

  .c-header__moto {
    height: 70vh;
  }
  .c-header__moto > h1 {
    margin: 0.5rem;
    font-size: 4rem;
  }
  .c-app__nav {
    display: flex;
    align-items: center;
    width: calc(100%);
    height: 96px;
    /* opacity: 0; */

    border-bottom-left-radius: 75px;
    border-bottom-right-radius: 75px;
    z-index: 50;
    /* position: absolute; */
    top: 0;
    left: 0;
  }

  .c-nav__item {
    margin-bottom: 0;
    padding: 0 1rem;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: atten-bold;
    color: var(--global-inactive);
    text-decoration: none;
    text-transform: uppercase;
  }

  /* .c-nav__item:hover{
    background-color: hotpink;
  } */

  .c-nav__link {
    height: 100%;
  }

  .c-nav__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    height: 96px;
    width: 100%;
  }

  .c-nav__link {
    font-size: 18px;
    font-family: atten-bold;
    color: var(--global-inactive);
    /* opacity: 0.5; */
    cursor: pointer;
  }

  .c-nav__logo {
    height: 64px;
    margin-left: 64px;
    margin-right: 32px;
  }
  .c-header__homepage {
    /* background-image: linear-gradient(
        rgba(59, 55, 53, 0.5),
        rgba(59, 55, 53, 0.5)
      ),
      url("Images/Headers/header_desk.png"); */
    border-radius: 0px 0px 200px 200px;
    height: 70vh;
  }
  .c-usercard__grid {
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
  }
  .c-afdelingen {
    width: 100%;
  }
  .c-shoprow {
    width: 100%;
  }
  .c-shoppage {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .c-categorycard {
    width: 20%;
    margin-right: 2rem;
    height: calc(90vh - (176px + 96px + 16px));
    max-height: calc(90vh - (176px + 96px));
    margin-bottom: 0;
  }
  .c-productdetail-container {
    max-width: 50%;
    /* margin: auto; */
    margin: 2rem auto;
  }
}
@media (min-width: 1200px) {
  .c-afdelingen {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  .c-map-container {
    width: 75%;
  }
  .c-afdeling-section {
    width: 25%;
    margin-right: 2rem;
    overflow: auto;
  }
  .c-menu-card {
    width: 15rem;
  }
  .c-shoprow {
    flex-direction: row;
  }
  .c-page > img {
    height: 20rem;
  }

  .c-page > h2 {
    font-size: 3rem;
    line-height: 5rem;
    padding: 0 1rem;
  }
  /* .c-menu-card__row {
    grid-template-columns: repeat(4, 1fr);
  }
  .c-menu-card {
    width: 320px;
    height: 160px;
  } */
}

.o-hide-accessible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media (min-width: 1080px) {
  .c-blogpost__head {
    /* height: 416px; */
  }
  .c-form {
    width: 50%;
    margin: auto;
  }
}
